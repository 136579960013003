import { usersAPI } from "../../api/data-analysis-api";
import { authenticate } from "../../api/user";
import loading from "./loading";
import { DEFAULT_STATE, LOGIN_SUCCESS } from "../../constants/general";
import { LOGIN_PAGE } from "../../config/environment";

/** Dispatch: fetch data action and received the data. Payload the data or the error message**/
export const getUserSuccess = (users) => {
    return {
        type: "GET_USERS_SUCCESS",
        payload: users,
    };
};

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    };
};

export const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("subject");
    localStorage.removeItem("GLOBAL_STATE");
    window.location.href = LOGIN_PAGE;
    return {
        type: DEFAULT_STATE,
    };
};

export const getUsersA = (username, userId, email, firstName, lastName, roleId, role, schoolId, sie,
    callDocument, limit, pageNumber) => {
    return async (dispatch, getState) => {
        try {
            dispatch(loading(true));
            const res = await usersAPI(username, userId, email, firstName, lastName, roleId, role, schoolId, sie,
                callDocument, limit, pageNumber)
            console.log("Res", res)
            const results = res.data.results;
            dispatch(getUserSuccess(results));
        } catch (e) {

        } finally {
            dispatch(loading(false));
        }
    }
}

export const login = (credentials, history) => {
    return async (dispatch, getState) => {
        try {
            dispatch(loading(true));
            const res = await authenticate(credentials);
            const user = {
                InvitationCode: res.data.InvitationCode,
                Name: res.data.Name,
                Roles: res.data.Roles,
                Token: res.data.Token,
                Username: res.data.Username,
            };
            localStorage.setItem("auth", JSON.stringify(user));
            dispatch(loginSuccess(user));
            history.push("/analysis/dashboard");
        } catch {
        } finally {
            dispatch(loading(false));
        }
    };
};
