import axios from "./interceptors";
import {
    environment,
} from "../config/environment";

/**
 * Get the grades (levels).
 * @returns {AxiosPromise}
 */
export const gradesApi = () => {
    return axios({
        method: "GET",
        url: `${environment.baseURL}/level/`,
        headers: { "Content-Type": "application/json" },
    });
};

/**
 * Get the subjects.
 * @returns {AxiosPromise}
 */
export const subjectsApi = () => {
    return axios({
        method: "GET",
        url: `${environment.baseURL}/subject/`,
        headers: { "Content-Type": "application/json" },
    });
};

/**
 * Get the roles.
 * @returns {AxiosPromise}
 */
export const rolesAPI = () => {
    return axios({
        method: "GET",
        url: `${environment.baseURL}/role/`,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Get the countries.
 * @param countryIso The country ISO.
 * @param country The country name.
 * @returns {AxiosPromise}
 */
export const countryAPI = (countryIso, country) =>{
    let url = `${environment.baseURL}/country/`;

    let hasParameterAlready = false;

    if(countryIso != null && countryIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country_iso=${countryIso}`;
        hasParameterAlready = true;
    }

    if(country != null && country.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country=${country}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Get the OREs.
 * @param countryIso The country iso.
 * @returns {AxiosPromise}
 */
export const oreAPI = (countryIso) =>{
    console.log("Here Calling API")
    let url = `${environment.baseURL}/ore/`;

    let hasParameterAlready = false;

    if(countryIso != null && countryIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country_iso=${countryIso}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Get the states.
 * @param countryIso The country iso.
 * @returns {AxiosPromise}
 */
export const statesAPI = (countryIso) =>{
    let url = `${environment.baseURL}/state/`;

    let hasParameterAlready = false;

    if(countryIso != null && countryIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country_iso=${countryIso}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Get the cities.
 * @param countryIso The country iso.
 * @param stateIso The state iso.
 * @param oreId the ore Id.
 * @returns {AxiosPromise}
 */
export const citiesAPI = (countryIso, stateIso, oreId) =>{
    let url = `${environment.baseURL}/city/`;

    let hasParameterAlready = false;

    if(countryIso != null && countryIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country_iso=${countryIso}`;
        hasParameterAlready = true;
    }

    if(stateIso != null && stateIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `state_iso_code=${stateIso}`;
        hasParameterAlready = true;
    }

    if(oreId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `ore_id=${oreId}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Get the schools api.
 * @param schoolType School Type if PRIVATE or PUBLIC.
 * @param city City.
 * @param oreId Ore Id.
 * @param stateIsoCode State ISO Code.
 * @param countryIso Country ISO Code.
 * @param schoolIdentifier School Identifier.
 * @param limit Get limits.
 * @returns {AxiosPromise}
 */
export const schoolsAPI = (schoolType, city, oreId, stateIsoCode, countryIso, schoolIdentifier, limit) => {
    let url = `${environment.baseURL}/school/`;

    let hasParameterAlready = false;

    if(schoolType != null && schoolType.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `school_type=${schoolType}`;
        hasParameterAlready = true;
    }

    if(city != null && city.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `city=${city}`;
        hasParameterAlready = true;
    }

    if(oreId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `ore_id=${oreId}`;
        hasParameterAlready = true;
    }
    if(stateIsoCode != null && stateIsoCode.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `state_iso_code=${stateIsoCode}`;
        hasParameterAlready = true;
    }

    if(countryIso != null && countryIso.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `country_iso=${countryIso}`;
        hasParameterAlready = true;
    }

    if(schoolIdentifier != null && schoolIdentifier.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `school_identifier=${schoolIdentifier}`;
        hasParameterAlready = true;
    }

    if(limit != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `limit=${limit}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * To get the users API call.
 * @param username Username filter.
 * @param userId UserId filter
 * @param email Email filter
 * @param firstName First name filter
 * @param lastName Last name filter
 * @param roleId role Id filter
 * @param role Role name filter
 * @param schoolId school Id filter
 * @param sie sie filter
 * @param oreId The Ore filter
 * @param callDocument If call the document to download or call the results for a data table.
 * @param limit The limit (total)
 * @param pageNumber The page number.
 * @returns {AxiosPromise}
 */
export const usersAPI = (username, userId, email, firstName, lastName, roleId, role, schoolId, sie, oreId, callDocument,
                         limit, pageNumber) => {
    let url = '';

    if(callDocument)
        url = `${environment.baseURL}/users-document/`;
    else
        url = `${environment.baseURL}/users-list/`;
    let hasParameterAlready = false;

    if(username != null && username.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `username=${username}`;
        hasParameterAlready = true;
    }

    if(userId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `user_id=${userId}`;
        hasParameterAlready = true;
    }

    if(email != null && email.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `email=${email}`;
        hasParameterAlready = true;
    }
    if(firstName != null && firstName.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `first_name=${firstName}`;
        hasParameterAlready = true;
    }

    if(lastName != null && lastName.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `last_name=${lastName}`;
        hasParameterAlready = true;
    }

    if(roleId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `role_id=${roleId}`;
        hasParameterAlready = true;
    }

    if(oreId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `ore_id=${oreId}`;
        hasParameterAlready = true;
    }

    if(role != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `role=${role}`;
        hasParameterAlready = true;
    }
    if(schoolId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `school_id=${schoolId}`;
        hasParameterAlready = true;
    }

    if(sie != null && sie.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `sie=${sie}`;
        hasParameterAlready = true;
    }

    if(limit != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `limit=${limit}`;
        hasParameterAlready = true;
    }

    if(pageNumber != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `offset=${pageNumber * limit}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * Returns the connected user details.
 * @param fromDate From Date. REQUIRED
 * @param toDate To Date. REQUIRED.
 * @param schoolId Id of the school if any (if principal or teacher, the school Id is going to be added on the backend).
 * @param oreId If of the Ore if any.
 * @param callDocument If call the excel document or not.
 * @param limit How many items per page.
 * @param pageNumber Number of page.
 * @returns {AxiosPromise}
 */
export const connectedAPI = (fromDate, toDate, schoolId, oreId, callDocument, limit, pageNumber) => {
    let url = '';

    if(callDocument)
        url = `${environment.baseURL}/connected-document/`;
    else
        url = `${environment.baseURL}/connected-list/`;
    let hasParameterAlready = false;

    if(fromDate != null ){
        fromDate = fromDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `from_date=${fromDate}`;
        hasParameterAlready = true;
    }

    if(toDate != null ){
        toDate = toDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `to_date=${toDate}`;
        hasParameterAlready = true;
    }

    if(schoolId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `school_id=${schoolId}`;
        hasParameterAlready = true;
    }

    if(oreId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `ore_id=${oreId}`;
        hasParameterAlready = true;
    }

    if(limit != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `limit=${limit}`;
        hasParameterAlready = true;
    }

    if(pageNumber != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `offset=${pageNumber * limit}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}

/**
 * To get the academics performance result.
 * @param fromDate From Date. REQUIRED.
 * @param toDate To Date. REQUIRED.
 * @param subject Subject.
 * @param level Level.
 * @param schoolId School Id.
 * @param oreId Ore Id.
 * @param callDocument If call the document or the data for datatable.
 * @param limit Quantity per page.
 * @param pageNumber Page number in pagination.
 * @returns {AxiosPromise}
 */
export const academicsAPI = (fromDate, toDate, subject, level, schoolId, oreId, callDocument, limit, pageNumber) =>{
    let url = '';

    if(callDocument)
        url = `${environment.baseURL}/results-document/`;
    else
        url = `${environment.baseURL}/results-list/`;

    let hasParameterAlready = false;

    if(fromDate != null ){
        fromDate = fromDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `from_date=${fromDate}`;
        hasParameterAlready = true;
    }

    if(toDate != null ){
        toDate = toDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `to_date=${toDate}`;
        hasParameterAlready = true;
    }

    if(subject != null && subject.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `subject=${subject}`;
        hasParameterAlready = true;
    }

    if(level != null && level.trim() !== ""){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `level=${level}`;
        hasParameterAlready = true;
    }

    if(schoolId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `school_id=${schoolId}`;
        hasParameterAlready = true;
    }

    if(oreId != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `ore_id=${oreId}`;
        hasParameterAlready = true;
    }

    if(limit != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `limit=${limit}`;
        hasParameterAlready = true;
    }

    if(pageNumber != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `offset=${pageNumber * limit}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });

}

/**
 * Get the activities (connections) of the user.
 * @param fromDate From date. REQUIRED.
 * @param toDate To date. REQUIRED.
 * @param callDocument If call document or not.
 * @param limit. Quantity per page.
 * @param pageNumber Number of page in pagination.
 * @returns {AxiosPromise}
 */
export const studentActivitiesByTeacherAPI = (fromDate, toDate, callDocument, limit, pageNumber) =>{
    let url = '';

    if(callDocument)
        url = `${environment.baseURL}/student-activity-by-teacher-document/`;
    else
        url = `${environment.baseURL}/student-activity-by-teacher-list/`;

    let hasParameterAlready = false;

    if(fromDate != null ){
        fromDate = fromDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `from_date=${fromDate}`;
        hasParameterAlready = true;
    }

    if(toDate != null ){
        toDate = toDate.toISOString().slice(0, 19).replace('T', ' ');
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `to_date=${toDate}`;
        hasParameterAlready = true;
    }

    if(limit != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `limit=${limit}`;
        hasParameterAlready = true;
    }

    if(pageNumber != null){
        if(url.indexOf('?') === -1)
            url += `?`;

        if(hasParameterAlready)
            url += `&`;

        url += `offset=${pageNumber * limit}`;
        hasParameterAlready = true;
    }

    return axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json" },
    });
}