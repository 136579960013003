export const isAuth = () => {
    return localStorage.getItem("auth") !== null;
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem("auth"));
};

export const getLanguage = () => {
    return localStorage.getItem("i18nextLng");
};

export const getMainRole = () => {
    let role = localStorage.getItem("auth");
    if(role !== null)
    {
        return (JSON.parse(role).Roles[0]).toUpperCase();
    }
    return null;
}
