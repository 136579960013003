import React, { Suspense, useState, useEffect } from "react";
import "./style.css";
import { Loader } from "../../common/styled-components/loader";
import { useSelector, useDispatch } from "react-redux";
import {getMainRole, isAuth} from "../../../utils/utils";
import { useHistory, Link } from "react-router-dom";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Select from 'react-select';
import {Input} from "@material-ui/core";
import {oreAPI, schoolsAPI, usersAPI, connectedAPI} from "../../../api/data-analysis-api";
import {currentORE} from "../../../redux/actions/ore-current";
import {currentSchool} from "../../../redux/actions/school-current";
import {FaUserLock, FaSearch, FaArrowCircleLeft, FaFilter, FaDownload} from "react-icons/all";
import Pagination from "@material-ui/lab/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Portal} from "react-overlays";
import {
    ROLE_ADMIN_NAME,
    ROLE_ADMIN_ORE_NAME,
    ROLE_EMPLOYEE_NAME,
    ROLE_PRINCIPAL_NAME
} from "../../../config/environment";

const ReportConnections = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation(["report-connections"]);
    const [ores, setOres] = React.useState([]);
    const [oreSelected, setOreSelected] = React.useState(null);
    const [schools, setSchools ] = React.useState([]);
    const [schoolSelected, setSchoolSelected] = React.useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalResults, setTotalResults] = React.useState(null);
    const [results, setResults] = useState([]);
    const [pagination, setPagination] = useState({});
    const [documentUrl, setDocumentUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const count = Math.ceil(totalResults / pageSize);

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");
        return <Portal container={el}>{children}</Portal>;
    };

    const handlePageChange = async (event, value) => {
        setLoading(true);
        setPage(value);
        let schoolId = schoolSelected != null ? schoolSelected.value : null;
        let oreId = oreSelected != null ? oreSelected.value: null;
        const res = await connectedAPI(startDate, endDate, schoolId, oreId,false, pageSize, value - 1);
        setTotalResults(res.data.count);
        setResults(res.data.results);
        setLoading(false);
    };

    useEffect(() => {
        setPagination({ page, pageSize });
    }, [page]);

    React.useEffect(()=>{
        (async ()=>{
            let role = getMainRole();
            if(role.localeCompare(ROLE_EMPLOYEE_NAME) === 0
                ||
                role.localeCompare(ROLE_ADMIN_NAME) === 0
                ||
                role.localeCompare(ROLE_ADMIN_ORE_NAME) === 0
            ) {
                const oreCall = await oreAPI(null);
                const ores = oreCall.data.results;
                const oreMap = ores.map((item) => ({
                    value: item.ore_id,
                    label: item.ore,
                    countryIso: item.country_iso
                }));
                setOres(oreMap);
            }
        })();
    }, []);

    React.useEffect(()=>{
        (async ()=>{
            let role = getMainRole();
            if(role.localeCompare(ROLE_EMPLOYEE_NAME) === 0
                ||
                role.localeCompare(ROLE_ADMIN_NAME) === 0
                ||
                role.localeCompare(ROLE_ADMIN_ORE_NAME) === 0
            ) {
                const schoolCall = await schoolsAPI(null, null,
                    oreSelected != null ? oreSelected.value : null,
                    null, null, null, 100);
                const schools = schoolCall.data.results;
                const schoolMap = schools.map((item) => ({
                    value: item.school_id,
                    label: item.school_name,
                    city: item.city
                }));
                setSchools(schoolMap);
            }
        })();
    }, [oreSelected]);

    useEffect(() => {
        if (isAuth()) {
            history.push("/analysis/connections");
        }

        let role = getMainRole();
        if(role.localeCompare(ROLE_EMPLOYEE_NAME) !== 0
            &&
            role.localeCompare(ROLE_ADMIN_NAME) !== 0
            &&
            role.localeCompare(ROLE_ADMIN_ORE_NAME) !== 0
            &&
            role.localeCompare(ROLE_PRINCIPAL_NAME) !== 0)
            {
                history.push("/analysis/dashboard");
            }
    }, []);


    const handleOreChange = (selected) =>{
        setOreSelected(selected);
        dispatch(currentORE(selected));
    }

    const handleSchoolChange = (selected)=>{
        setSchoolSelected(selected);
        dispatch(currentSchool(selected));
    }

    const onFormSearch = async ()=>{
        setLoading(true);
        setDocumentUrl(null);
        let schoolId = schoolSelected != null ? schoolSelected.value : null;
        let oreId = oreSelected != null ? oreSelected.value: null;
        const res = await connectedAPI(startDate, endDate, schoolId, oreId, false, pageSize, 0);
        setPage(0)
        setTotalResults(res.data.count);
        setResults(res.data.results);
        setLoading(false);
    }

    const onDocumentSearch = async ()=>{
        console.log(schoolSelected);
        setLoading(true);
        let schoolId = schoolSelected != null ? schoolSelected.value : null;
        let oreId = oreSelected != null ? oreSelected.value: null;
        const res = await connectedAPI(startDate, endDate, schoolId, oreId, true, pageSize, 0);
        if(typeof res.data !== undefined && res.data.file !== ""){
            const link = document.createElement('a');
            link.href = res.data.file;
            link.setAttribute('download', res.data.file);
            document.body.appendChild(link);
            link.click();
        }
        setLoading(false);
    }

    return (
        <>
            <Container className="deliverables-wrapper-container col-responsive-m7">
                <Row>
                    <Col>
                        <Link className="btn-back" to="analysis/dashboard"><FaArrowCircleLeft/> {t("back")}</Link>
                        <br/>
                        <h1 className="title margin-top-30">
                            {' '} {t("report-title")}
                        </h1>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="box-shadow animated animate__animated animate__fadeInLeft reports-clear-card module-filter mt-negative">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <h6 className="title-filter mb-big"> <FaFilter/> {' '} {t("filters")}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className="wraper-input mt-small margin-left-1  wrapper-input-datapicker-responsive">
                                        <div className="wrapper-input__col"><sup className="label-reports-date">{t("from")}</sup> <DatePicker selected={startDate} popperContainer={CalendarContainer} onChange={date => setStartDate(date)} /></div>
                                        <div className="wrapper-input__col"><sup className="label-reports-date">{t("to")}</sup>{' '}<DatePicker selected={endDate} popperContainer={CalendarContainer} onChange={date => setEndDate(date)} /></div>
                                    </div>

                                    {getMainRole() === ROLE_EMPLOYEE_NAME || getMainRole() === ROLE_ADMIN_NAME
                                    || getMainRole() === ROLE_ADMIN_ORE_NAME ?
                                    <Col xs={12} md={7}>
                                        <div className="wraper-input">
                                            <div className="wrapper-input__col wrapper-input__col-two-size">
                                                <Select
                                                    className="selectTrello"
                                                    menuPortalTarget={document.body}
                                                    placeholder={t("ore")}
                                                    onChange={handleOreChange}
                                                    options={ores}
                                                    isMulti={false}
                                                    />
                                            </div>
                                            <div className="wrapper-input__col wrapper-input__col-two-size">
                                            {setOreSelected != null ?
                                                <Select
                                                    className="selectTrello"
                                                    menuPortalTarget={document.body}
                                                    value={schoolSelected}
                                                    placeholder={t("school")}
                                                    onChange={handleSchoolChange}
                                                    options={schools}
                                                    isMulti={false}
                                                /> : null}
                                            </div>
                                        </div>
                                    </Col> : null}
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="button-report">
                                            <button className="button-secondary" onClick={onFormSearch}>
                                                <FaSearch/> {' '}
                                                {t("search")}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                </Row>

                <Row>
                    <Col className="animated animate__animated animate__fadeInLeft text-right">
                        <div className="wrapper-reports-btn-results">
                        {
                            results.length > 0 ?
                                <>
                                    <button onClick={onDocumentSearch} className="button-download-document animated animate__animated animate__fadeIn">
                                        <FaDownload/> {t("download-excel")}
                                    </button>
                                    {' '}
                                    <b className="animated animate__animated animate__fadeIn">{t("total")}: {totalResults} </b>
                                </> : null
                        }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="animated animate__animated animate__fadeIn">
                        <Card className="box-shadow animated animate__animated animate__fadeInLeft reports-clear-card">
                            <Card.Body>
                                {
                                    results.length > 0 ?
                                    <table className="animated animate__animated animate__fadeIn content-table-users">
                                        <thead>
                                        <tr>
                                            <th>{t("sie")}</th>
                                            <th>{t("user")}</th>
                                            <th>{t("connected-date")} (UTC)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                    {results.map((item, i)=>(
                                        <tr key={i}>
                                            <td>
                                                <span className="table-users--title">
                                                {item.sie}
                                                </span>
                                            </td>
                                            <td>
                                                 <span className="table-users--general">
                                                    {item.first_name}{' '} {item.last_name}
                                                    <br/>
                                                    <small>{item.email}</small>
                                                     <br/>
                                                     <small><b>{item.role}</b></small>
                                                 </span>
                                            </td>
                                            <td>
                                                <span className="table-users--general table-users--date">
                                                {item.connected_date}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                        </tbody>
                                    </table> :
                                        <div className="text-center center-item margin-top-30">
                                        <FaSearch className="gray-text" size={60}/>
                                        <br/>
                                        <p className="gray-text"> {t("results")}</p>
                                        </div>
                                }
                            </Card.Body>
                        </Card>
                        <br/>
                        {count > 0 && (
                            <Pagination
                                count={count}
                                page={page}
                                onChange={handlePageChange}
                            />
                        )}
                    </Col>
                </Row>
                {loading ? <Loader /> : null}
            </Container>
        </>
    );
};

export default ReportConnections;
