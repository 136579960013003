import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

const FooterLogin = () => {
  const { t, i18n } = useTranslation(["login"]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <footer className="main-footer">
        <div className="footer-languages-login">
          <ul className="footer-languages_languages">
            <li>
              <i className="fas fa-globe"></i>
            </li>
            <li>
              <a onClick={() => changeLanguage("es")}>
                {" "}
                Esp<span>añol</span>{" "}
              </a>
            </li>
            <li>
              <a onClick={() => changeLanguage("en")}>
                {" "}
                Eng<span>lish</span>{" "}
              </a>
            </li>
          </ul>
          <p>{t("authorized")}</p>
          <p>{t("service")} (787) 302-3150</p>
        </div>
        <div className="footer-copyright">
          <p> © {t("copyrigth")}</p>
        </div>
      </footer>
    </>
  );
};

export default FooterLogin;
