import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from '../../utils/utils';

const PrivateRouter = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuth() ? (
            <Component {...props} />
        ) :
            (
                <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
            )
    )} />
);

export default PrivateRouter;