import React, { Suspense, useState, useEffect } from "react";
import "./style.css";
import { Loader } from "../../common/styled-components/loader";
import { useSelector, useDispatch } from "react-redux";
import {getMainRole, isAuth} from "../../../utils/utils";
import { useHistory, Link } from "react-router-dom";
import {Container, Row, Col, Card, Button} from "react-bootstrap";
import FakeChart from "../../../assets/images/chart_fake.png";
import {useTranslation} from "react-i18next";
import {FaUsers, FaChartLine, FaUserLock, FaExclamationCircle} from "react-icons/all";
import {
    ROLE_ADMIN_NAME,
    ROLE_ADMIN_ORE_NAME,
    ROLE_EMPLOYEE_NAME,
    ROLE_PRINCIPAL_NAME,
    ROLE_TEACHER_NAME
} from "../../../config/environment";
const Dashboard = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation(["home"]);
    const { loading } = useSelector((state) => ({
        loading: state.loading,
    }));

    useEffect(() => {
        if (isAuth()) {
            history.push("/analysis/dashboard");
        }
    }, []);

    return (
        <>
            {/*<Menu/>*/}
        <Container>
            <Row className="animated animate__animated animate__fadeIn">
                <Col xs={4} md={3} lg={2}>
                    <img className="chart-fake" src={FakeChart} alt="" />
                </Col>
                <Col>
                    <h2 className="dashboard-title">{t("dashboard-welcome")}</h2>
                    <div className="alert-message">
                        <small>
                            <FaExclamationCircle />{" "}
                            {t("dashboard-note")}</small>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr/>
                </Col>
            </Row>

            <Row>
                {getMainRole() === ROLE_ADMIN_NAME || getMainRole() === ROLE_EMPLOYEE_NAME ||
                    getMainRole() === ROLE_ADMIN_ORE_NAME || getMainRole() === ROLE_PRINCIPAL_NAME?
                <Col xs={12} sm={4} className="margin-top-20 reports-card">
                    <Card className="box-shadow animated animate__animated animate__fadeInLeft">
                        <Card.Body className="text-center reports-link">
                            <Link to="/analysis/users">
                            <FaUsers size={70} className="grayish-text"/>
                                <h4 className="section-title">
                                    {t("report-users")}
                                </h4>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col> : null}
                {getMainRole() !== ROLE_TEACHER_NAME ?
                <Col xs={12} sm={4} className="margin-top-20 reports-card">
                    <Card className="box-shadow animated animate__animated animate__fadeInRight">
                        <Card.Body className="text-center reports-link">
                            <Link to="/analysis/connections">
                                <FaUserLock size={70} className="grayish-text"/>
                                <h4 className="section-title">
                                    {t("report-connections")}
                                </h4>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col> : null}
                {getMainRole() === ROLE_TEACHER_NAME ?
                <Col xs={12} sm={4} className="margin-top-20 reports-card">
                    <Card className="box-shadow animated animate__animated animate__fadeInRight">
                        <Card.Body className="text-center reports-link">
                            <Link to="/analysis/student-activities-teacher">
                                <FaUserLock size={70} className="grayish-text"/>
                                <h4 className="section-title">
                                    {t("report-connections")}
                                </h4>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col> : null}
                <Col xs={12} sm={4} className="margin-top-20 reports-card">
                    <Card className="box-shadow animated animate__animated animate__fadeInLeft">
                        <Card.Body className="text-center reports-link">
                            <Link to="/analysis/academics">
                                <FaChartLine size={70} className="grayish-text"/>
                                <h4 className="section-title">
                                    {t("report-academic")}
                                </h4>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {loading && <Loader />}
        </Container>
            </>
    );
};

export default Dashboard;
