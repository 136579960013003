import axios from "./interceptors";
import {
  environment,
  USERS_API_URL
} from "../config/environment";
import { getLanguage } from "../utils/utils";

export const authenticate = (credentials) => {
  const data = {
    LangCode: getLanguage(),
    Username: credentials.username,
    Password: credentials.password,
  };
  return axios({
    method: "POST",
    url: `${USERS_API_URL}/api/login/client`,
    data: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const loginSso = (idToken) =>{
  let data = {
      Token: idToken,
      Domain: "data-analysis"        
  };
  return axios({
      method: "POST",
      url: `${USERS_API_URL}/api/login/sso`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(data)
  });
};