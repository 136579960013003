const defaultState = {};

/** modifies a piece of data **/
function reducer(state = defaultState, action) {
    switch (action.type) {
        case "GET_COUNTRY_SUCCESS":
            return action.payload;
        default:
            return state;
    }
}

export default reducer;
