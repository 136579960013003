import React from "react";
import "./style.css";
import AvatarIcon from "../../../../assets/images/sample-avatar.png";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../redux/actions/users";
import { useTranslation } from "react-i18next";

const DropdownUser = ({ setIsComponentVisible, user }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation(["home"]);

    const onLogout = () => {
        setIsComponentVisible(false);
        dispatch(logout());
    };

    const goToHome = () => {
        history.push("/analysis/dashboard");
        setIsComponentVisible(false);
    };

    return (
        <>
            <ul className="user-comp_menu">
                <div className="user-comp_info-user">
                    <div className="double-border">
                        <img src={AvatarIcon} alt="" />
                    </div>
                    <h1>{user.Name}</h1>
                    <h4>
                        {t("code")}: {user.InvitationCode}
                    </h4>
                </div>

                <ul className="user-comp_submenu">
                    <li>
                        <a onClick={goToHome}>
                            <i className="fas fa-home"/> {t("home")}{" "}
                        </a>
                    </li>
                    <li>
                        <a onClick={onLogout}>
                            <i className="fas fa-sign-out-alt"/> {t("logout")}{" "}
                        </a>
                    </li>
                </ul>
            </ul>
        </>
    );
};

export default DropdownUser;
