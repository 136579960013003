import axios from "axios";
import { getLanguage, getUser } from "../utils/utils";
import { clearError, handleError } from "../redux/actions/error";
import { logout } from "../redux/actions/users";
import store from "../redux/store";

const instance = axios.create();

instance.interceptors.request.use((req) => {
    req.headers["Accept-Language"] = getLanguage();
    req.headers["Token"] = getUser() ? getUser().Token : "";
    store.dispatch(clearError());
    return req;
});

instance.interceptors.response.use(null, (error) => {
    const errrorMessage = handleErrorMessage(error);
    store.dispatch(handleError(errrorMessage));
    return Promise.reject(error);
});

export default instance;

export const handleErrorMessage = (error) => {
    let message;

    const ErrorMessages = {
        400: "There was Some Problem, while processing your Request",
        401: "Unauthorized, You are not Allowed",
        403: "Sorry, You are not allowed for This Action",
        404: "API Route is Missing or Undefined",
        405: "API Route Method Not Allowed",
        500: "Server Error, please try again later",
        request: "There is Some Problem With Our Servers, Please Try again Later",
        other: "There was some Problem with your Request, Please Try again Later",
    };

    if (error && error.message === "Network Error" && !error.response) {
        message = "Network Error - make sure API is running";
    }

    if (error && error.response) {
        if (error.response.status === 400) {
            const msg = error.response.data.Messages[0];
            message = msg ? msg : ErrorMessages["400"];
        } else if (error.response.status === 401) {
            const msg = error.response.data.Messages[0];
            message = msg ? msg : ErrorMessages["401"];
            console.log(msg)
            store.dispatch(logout());
        } else if (error.response.status === 403) {
            message = ErrorMessages["403"];
        } else if (error.response.status === 404) {
            message = ErrorMessages["404"];
        } else if (error.response.status === 405) {
            message = ErrorMessages["405"];
        } else if (error.response.status >= 500) {
            message = ErrorMessages["500"];
        }
    } else if (error && error.request) {
        message = ErrorMessages.request;
    } else if (error instanceof Error) {
        message = error.message;
    } else if (typeof error === "string") {
        message = error;
    } else {
        message = ErrorMessages.other;
    }
    return message;
};
